import React from "react";
import { graphql, PageProps } from "gatsby";

import { useStoryblok } from "@/base/lib/storyblok";
import Layout from "@/components/layouts/Layout";
import NotFound from "@/components/sections/NotFound";
import CommonPostSection from "@/components/sections/CommonPostSection";

interface PageData {
  layout: StoryblokStory;
  featuredNews: {
    edges: {
      node: StoryblokStory;
    }[];
  };
  featuredPerspectives: {
    edges: {
      node: StoryblokStory;
    }[];
  };
  categories?: { edges: { node: PostCategoryDataSource }[] };
}
const NotFoundPage = ({ location, data }: PageProps<PageData>) => {
  const { layout } = data;
  const pageStory = useStoryblok<CommonLayoutType | CommonPostType>(
    layout,
    location
  );

  const parsedLayout =
    layout && typeof layout.content === "string"
      ? (JSON.parse(layout.content) as CommonLayoutType)
      : (layout.content as unknown as CommonLayoutType);
  let { header, footer } = parsedLayout;

  //edit mode; in storyblok editor
  if (
    pageStory?.content?.component === "common_post" &&
    !pageStory?.content?.external_post
  ) {
    const { content } = pageStory;
    const { metadata } = content;
    const { featuredNews, featuredPerspectives, categories } = data;
    const category = content?.post_category;
    const categoryLabel = categories?.edges?.find(
      ({ node }) => node?.value === category
    )?.node?.name;
    const parsedFeaturedNews = featuredNews.edges.map(({ node }) => ({
      ...node,
      content: JSON.parse(node.content) as CommonPostType,
    }));
    const parsedFeaturedPerspectives = featuredPerspectives.edges.map(
      ({ node }) => ({
        ...node,
        content: JSON.parse(node.content) as CommonPostType,
      })
    );
    return (
      <Layout
        header={header && header[0]}
        footer={footer && footer[0]}
        seo={{
          ...metadata,
          og_type: "article",
          og_image: metadata?.og_image || content?.post_hero?.image,
        }}
        siteName={pageStory.name}
        pathname={location.pathname}
        hash={location.hash}
        languages={[]}
        lang="en"
      >
        <CommonPostSection
          path={location.href}
          lang={"en"}
          featuredNews={
            category === "news"
              ? parsedFeaturedNews
              : parsedFeaturedPerspectives
          }
          postContent={content}
          categoryLabel={categoryLabel}
        />
      </Layout>
    );
  }
  header = (pageStory.content as CommonLayoutType).header;
  footer = (pageStory.content as CommonLayoutType).footer;

  //page not found
  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      siteName={pageStory.name}
      pathname={location.pathname}
      hash={location.hash}
      languages={[]}
      lang="en"
    >
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query Page404Query {
    layout: storyblokEntry(field_component: { eq: "common_layout" }) {
      content
    }
    featuredNews: allStoryblokEntry(
      filter: {
        field_component: { eq: "common_post" }
        field_post_category_string: { eq: "news" }
      }
      sort: { order: DESC, fields: [field_post_date_string] }
      limit: 4
    ) {
      edges {
        node {
          uuid
          content
          slug
          full_slug
        }
      }
    }
    featuredPerspectives: allStoryblokEntry(
      filter: {
        field_component: { eq: "common_post" }
        field_post_category_string: { eq: "perspectives" }
      }
      sort: { order: DESC, fields: [field_post_date_string] }
      limit: 4
    ) {
      edges {
        node {
          uuid
          content
          slug
          full_slug
        }
      }
    }
    categories: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "post-categories" } }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
