import React from "react";

import LinkComponent from "@/components/elements/Link";
import notFoundImg from "@/assets/img/404-image.jpg";

import * as styles from "./styles.module.css";

function NotFound() {
  return (
    <section className={styles.wrapper}>
      <img src={notFoundImg} alt="" />
      <h3>Oops! The page you were looking for doesn&apos;t exist.</h3>
      {<LinkComponent url="/" title="Back to Homepage" type="internal" />}
    </section>
  );
}

export default NotFound;
